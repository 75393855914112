.total-summary {
    padding-left: 33px;
    margin-top: -13px;
}
.total-entry {
    padding-left: 280px;
}
.vs-collapsible-panel-item {
    padding: 0px;
}
.detail-filter-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-bottom: 10px;
}

.detail-filter {
    display: flex;
    align-items: center;
    margin-left: 20px;
}

.detail-filter-label {
    font-weight: 500;
    margin-right: 4px;
}

.detail-filter-select {
    width: 200px;
    margin-bottom: 0 !important;
}
