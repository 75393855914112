.home-page {
  margin: 0px 16px;
  flex-grow: 1;
}

.home-page-card-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.home-page-card {
  padding: 20px;
  margin-top: 0px;
  margin-bottom: 20px;
  margin-right: 20px;
  max-width: 100%;
  min-height: 115px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  background-color: #f6f6f6;
}

@media(min-width: 600px) {
  .home-page-card {
    max-width: 100%;
    flex-basis: 100%;
  }
}

@media(min-width: 960px) {
  .home-page-card {
    max-width: 47.5%;
    flex-basis: 48%;
  }
}

@media(min-width: 1280px) {
  .home-page-card {
    max-width: 47.5%;
    flex-basis: 48%;
  }
}

@media(min-width: 1920px) {
  .home-page-card {
    max-width: 33.33%;
    flex-basis: 32%;
  }
}

.home-page-card span {
  font-size: 17px;
  font-weight: 600;
  margin-bottom: 10px;
}

.home-page-card-title {
  display: flex;
  flex-direction: column;
}
